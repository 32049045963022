export const HTTP_STATUS_CODE = {
  CREATED: 201,
  SUCCESS: 200,
  VALIDATION_ERROR: 400,
  AUTHORIZATION_ERROR: 403,
  FAIL: 500,
};

export const OS = {
  ANDROID: "android",
  IOS: "ios",
};

export const ERROR_MESSAGES = {
  GENERIC: "エラーが発生しました。ご確認の上、もう一度お試しください。",
  IMAGE_UPLOAD_FAILED:
    "画像アップロードに失敗しました。お手数ですが再度お試しください",
  VALIDATION: "入力内容が不正です。ご確認の上、もう一度お試しください",
  EXTERNAL_BROWSER_NOT_SUPPORTED: "外部ブラウザはサポートされていません",
};

export const JST = {
  TIMEZONE: "Asia/Tokyo",
  DATE_FORMAT: "YYYY-MM-DDTHH:mm:ss",
};
