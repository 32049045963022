import Joi from "joi";
import { SurveyPages } from "../types/Survey";

export const surveyPatternTemplateSchema = {
  schema: Joi.object({
    theme: Joi.object({
      primary_color: Joi.string().allow(""),
      secondary_color: Joi.string().allow(""),
      font_color: Joi.string().allow(""),
    }),
    page_title: Joi.object({
      main: Joi.string().required(),
    }).required(),
    footer_section: Joi.object({
      text: Joi.string().allow(""),
    }).required(),
    survey_page: Joi.object({
      header_image: Joi.string().allow(""),
      lead_sentence: Joi.string().required(),
      dynamic_survey: Joi.array()
        .items(
          Joi.object({
            sort_order: Joi.number().required(),
            question_id: Joi.number().required(),
            question: Joi.string().required(),
            required: Joi.boolean().required(),
            type: Joi.string()
              .valid("radio", "checkbox", "pulldown", "text", "textarea")
              .required(),
            options: Joi.array().items(Joi.string().required()),
            csv_header: Joi.string().required(),
            max_length: Joi.when("type", {
              switch: [
                { is: "text", then: Joi.number().optional() },
                { is: "textarea", then: Joi.number().optional() },
              ],
            }),
            min_length: Joi.when("type", {
              switch: [
                { is: "text", then: Joi.number().required() },
                { is: "textarea", then: Joi.number().required() },
              ],
            }),
          }).optional(),
        )
        .required(),
      max_receipts: Joi.number().required(),
      buttons: Joi.object({
        upload: Joi.string().allow(""),
        submit: Joi.string().required(),
      })
        .unknown(true)
        .required(),
    }).required(),
    complete_page: Joi.object({
      header_image: Joi.string().allow(""),
      image: Joi.string().allow(""),
      application_sent_message: Joi.string().allow("").required(),
      text: Joi.string().allow(""),
      push_message: Joi.string().allow(""),
    }).required(),
    already_applied_page: Joi.object({
      header_image: Joi.string().allow(""),
      image: Joi.string().allow(""),
      text: Joi.string().allow(""),
      has_reached_app_restriction: Joi.boolean().required(),
      app_restriction: [Joi.string().required(), Joi.allow("").required()],
    }).required(),
    error_page: Joi.object({
      header_image: Joi.string().allow(""),
      image: Joi.string().allow(""),
      text: Joi.string().allow("").required(),
    }).required(),
    campaign_end_page: Joi.object({
      img: Joi.string().allow(""),
      text: Joi.string().allow("").required(),
      date: Joi.date().allow("").required(),
    }).unknown(true),
    add_friend_page: Joi.object({
      header_image: Joi.string().allow(""),
      image: Joi.string().allow(""),
      text: Joi.string().allow("").required(),
      button_text: Joi.string().allow("").required(),
    }).required(),
  }),
  isValid: function (template: SurveyPages) {
    const { error } = this.schema.validate(template);
    if (error === undefined || error === null) {
      return true;
    }
    console.error(error);
    return false;
  },
};
