import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { HTTP_STATUS_CODE, ERROR_MESSAGES } from "./constants";
import { SurveyPages, SurveyPayload } from "./types/Survey";
import { PresignedUrl } from "./types";

const CP_API_URL = process.env.REACT_APP_CP_API_URL;
const CP_API_KEY = process.env.REACT_APP_CP_API_KEY;
const { VALIDATION_ERROR, FAIL } = HTTP_STATUS_CODE;
const { GENERIC: GENERIC_MESSAGE, VALIDATION: VALIDATION_MESSAGE } =
  ERROR_MESSAGES;

interface ApiInstance {
  [x: number]: string;
  axiosInstance: AxiosInstance;
  endpoint: string;
  genericError: string;
  get: () => Promise<SurveyPages> | Promise<PresignedUrl>;
  post: (body: object) => Promise<AxiosResponse>;
  errorHandling: (e: AxiosError) => never;
}

/** CP API instance
 *  - Set CP API URL from env variable (CP_API_URL)
 */
const cpApi: AxiosInstance = axios.create();
cpApi.defaults.baseURL = CP_API_URL;
cpApi.interceptors.request.use((config) => {
  config.headers["x-api-key"] = CP_API_KEY;
  return config;
});
/** End */

const apiInstance = {
  axiosInstance: cpApi,
  endpoint: "",
  [VALIDATION_ERROR]: VALIDATION_MESSAGE,
  [FAIL]: GENERIC_MESSAGE,
  genericError: GENERIC_MESSAGE,
  get: function () {
    return this.axiosInstance
      .get(this.endpoint)
      .then((res) => res.data)
      .catch((e) => this.errorHandling(e));
  },
  post: function (body: object) {
    return this.axiosInstance
      .post(this.endpoint, body)
      .then((res) => res.data)
      .catch((e) => this.errorHandling(e));
  },
  errorHandling: function (e: AxiosError) {
    const { response } = e;
    if (response !== undefined) {
      switch (response.status) {
        case VALIDATION_ERROR:
          throw this[VALIDATION_ERROR];
        case FAIL:
          throw this[FAIL];
        default:
          throw this.genericError;
      }
    } else {
      throw this.genericError;
    }
  },
};

const cpApiInstance = { ...apiInstance };

export const getSurvey = (userId: string): Promise<SurveyPages> => {
  const instance = { ...cpApiInstance };
  instance.endpoint = `/survey?uid=${userId}`;
  instance.genericError = `${GENERIC_MESSAGE} [Err004]`;
  return instance.get() as Promise<SurveyPages>;
};

export const submitSurvey = (
  requestBody: SurveyPayload,
): Promise<AxiosResponse> => {
  const instance: ApiInstance = { ...cpApiInstance };
  instance.endpoint = "/survey";
  instance.genericError = `${GENERIC_MESSAGE} [Err005]`;
  return instance.post(requestBody);
};

export const getPresignedUrl = (
  fileExtension: string,
): Promise<PresignedUrl> => {
  const instance: ApiInstance = { ...cpApiInstance };
  instance.endpoint = "/presignedurls?ext=" + fileExtension;
  instance.genericError = `${GENERIC_MESSAGE} [Err006]`;
  return instance.get() as Promise<PresignedUrl>;
};
