import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import liff from "@line/liff";

import { Dimmer, Loader, Message } from "semantic-ui-react";

import { surveyPatternTemplateSchema } from "./util/schema.util";
import { ERROR_MESSAGES } from "./constants";
import { getSurvey } from "./api";
import { SurveyPages } from "./types/Survey";
import { LiffError } from "./types/Error";
import { LineProfile } from "./types";

const SurveyPage = React.lazy(() => import("./pages/Survey"));
const LINE_AUTH_INVALID_REQUEST = "invalid_request";

const App = () => {
  const isLocal = process.env.REACT_APP_PROFILE === "local";
  const shouldUseDummySurvey =
    process.env.REACT_APP_USE_DUMMY_SURVEY === "true";

  const [survey, setSurvey] = useState<SurveyPages | object>({});
  const [pageTitle, setPageTitle] = useState("-");
  const [isLoading, setIsLoading] = useState(true);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [userProfile, setUserProfile] = useState<LineProfile>({});
  const [isInClient, setIsInClient] = useState(false);
  const [isFriend, setIsFriend] = useState(true);
  const [isWebSupported, setisWebSupported] = useState<boolean | null>(null);
  const [os, setOs] = useState("");

  // initialize LIFF
  useEffect(() => {
    const initializeLiff = async () => {
      try {
        /*
         * Todo: liff.init not resolving
         * temporary workaround: if liff.init takes longer than 3 seconds, reload page.
         */
        const liffInitTimeout = new Promise((resolve) => {
          const id = setTimeout(() => {
            clearTimeout(id);
            resolve("timeout");
          }, 3000);
        });
        const liffId: string = process.env.REACT_APP_LIFF_ID ?? "";
        const q = await Promise.race([
          liff.init({ liffId: liffId }),
          liffInitTimeout,
        ]);
        const isLiffTimedOut = q === "timeout";
        !isLiffTimedOut ? setIsInitialRender(false) : window.location.reload();
      } catch (error) {
        alert("liff error");
        setIsLoading(false);
      }
    };

    initializeLiff();
  }, []);

  // check if app running on LIFF browser
  useEffect(() => {
    if (!isInitialRender) {
      const checkUserFlow = async () => {
        const isInClient = liff.isInClient();
        setIsInClient(isInClient);

        const os = liff.getOS() ?? "";
        setOs(os);
        const isLoggedIn = liff.isLoggedIn();
        const isWebEnabled = process.env.REACT_APP_BROWSER_SUPPORT;

        try {
          // web enabled

          if (isWebEnabled === "true") {
            if (isInClient || isLocal || isLoggedIn) {
              // get user profile
              let profile = null;
              try {
                profile = await getLiffUserProfile(isLocal);
              } catch (error: unknown) {
                const { code: errorCode } = error as LiffError;
                if (errorCode && errorCode === LINE_AUTH_INVALID_REQUEST) {
                  liff.logout();
                  liff.login({ redirectUri: window.location.href });
                  return;
                } else {
                  alert(`${ERROR_MESSAGES.GENERIC} [Err001]`);
                }
              }

              const userId = profile?.userId ?? "";

              // set user profile
              setUserProfile(profile ?? {});

              // get friendship
              if (!isLocal) {
                const friendship = await liff.getFriendship();
                setIsFriend(friendship.friendFlag);
              }

              // get survey
              await getSurveyDetails(userId);
            } else {
              liff.login({ redirectUri: window.location.href });
            }
          } else {
            if (isInClient || isLocal) {
              // get user profile
              let profile = null;
              try {
                profile = await getLiffUserProfile(isLocal);
              } catch (error: unknown) {
                const { code: errorCode } = error as LiffError;
                if (errorCode && errorCode === LINE_AUTH_INVALID_REQUEST) {
                  liff.logout();
                  liff.login({ redirectUri: window.location.href });
                  return;
                } else {
                  alert(`${ERROR_MESSAGES.GENERIC} [Err001]`);
                }
              }

              const userId = profile?.userId ?? "";

              // set user profile
              setUserProfile(profile ?? {});

              // get survey
              await getSurveyDetails(userId);
            } else {
              setisWebSupported(false);
            }
          }
        } catch (error) {
          alert(`${ERROR_MESSAGES.GENERIC} [Err003]`);
        } finally {
          setIsLoading(false);
        }
      };

      checkUserFlow();
    }
  }, [isInitialRender]);

  const getLiffUserProfile = async (isLocal: boolean) => {
    if (isLocal) {
      const { lineUserData } = await import("./devfiles/localData");
      return lineUserData;
    }

    const profile = await liff.getProfile();
    return profile;
  };

  const getSurveyDetails = async (userId: string): Promise<void> => {
    try {
      const { surveyData } = await import("./devfiles/localData");
      const survey =
        isLocal && shouldUseDummySurvey ? surveyData : await getSurvey(userId);
      if (survey && surveyPatternTemplateSchema.isValid(survey)) {
        // set survey
        setSurvey(survey ?? {});
      } else {
        console.error("schema validation error");
        alert(`${ERROR_MESSAGES.GENERIC} [Err002]`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Suspense
        fallback={
          <Dimmer active>
            <Loader>読込中</Loader>
          </Dimmer>
        }
      >
        {isLoading ? (
          <Dimmer active>
            <Loader>読込中</Loader>
          </Dimmer>
        ) : isWebSupported === false ? (
          <div className="container">
            <Message negative>
              <Message.Header>
                {ERROR_MESSAGES.EXTERNAL_BROWSER_NOT_SUPPORTED}
              </Message.Header>
            </Message>
          </div>
        ) : (
          <SurveyPage
            survey={survey}
            userProfile={userProfile}
            setPageTitle={setPageTitle}
            isFriend={isFriend}
            isLocal={isLocal}
            isInClient={isInClient}
            os={os}
          />
        )}
      </Suspense>
    </>
  );
};

export default App;
